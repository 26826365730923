<template lang="pug">
  footer
    p Copyright &copy; 2021 北京蜗小匠食品有限公司 保留所有权利。
    a(href="https://beian.miit.gov.cn") 京ICP备2021004104号
    router-link(to="/license") 营业执照
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
footer {
  padding: 0.04rem 0 .16rem;
  width: 100%;
  line-height: .16rem;
  background: white;
  font-size: .1rem;
  text-align: center;
  a {
    margin-right: .2rem;
    color: #2c3e50;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin: .05rem 0;
    color: #666;
  }
}
</style>
